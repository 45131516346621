/* eslint-disable generator-star-spacing */
import { Instance, SnapshotOut, types, flow } from "mobx-state-tree"
import { withEnvironment, withRootStore } from ".."
import { translate } from "../../i18n"
import { GetGeneralResult } from "../../services/api"
import { ParseApi } from "../../services/api/parse-api"
import { RcApi } from "../../services/api/rc-api"
import { SubscriptionModel } from "../subscription/subscription"
/**
 * Model description here for TypeScript hints.
 */

const thirtyDays = 1000 * 60 * 60 * 24 * 30

export const AccountModel = types
  .model("Account")
  .props({
    parseId: types.optional(types.string, ""),
    subscription: types.maybe(types.reference(SubscriptionModel)),
    subscriptionUpdatedAt: types.maybe(types.Date),
    code: types.maybe(types.string),
    createdAt: types.maybe(types.Date),
    state: types.enumeration("State", ["pending", "done", "error"]),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({
    get hasFreeTime() {
      if (self.createdAt) {
        return Date.now().valueOf() - thirtyDays < self.createdAt.valueOf()
      } else {
        return false
      }
    },
    get freeTimeLeft() {
      return new Date(Date.now().valueOf() - self.createdAt.valueOf())
    },
    get freeDaysLeft() {
      return ((Date.now().valueOf() - self.createdAt.valueOf()) / 1000 / 60 / 60 / 24).toString()
    },
    get hasCode() {
      return !!self.code
    },
    get hasSubscription() {
      return !!self.subscription
    },
  }))
  .actions((self) => ({
    // move login, singup and co here
    changePassword: flow(function* changePassword(password) {
      self.state = "pending"
      const parseApi = new ParseApi()
      parseApi.apisauce.setHeader("X-Parse-Session-Token", self.rootStore.sessiontoken)
      try {
        __DEV__ && console.log("Change Password attempt")
        const result: GetGeneralResult = yield parseApi.changePassword(self.parseId, password)
        if (result.kind === "ok") {
          self.rootStore.setSessiontoken(result.data.sessiontoken)
          self.state = "done"
          return "success"
        } else {
          __DEV__ && console.log("Password change Failed : ", result)
          self.state = "done"
          return translate("errors." + result.kind)
        }
      } catch (e) {
        console.log(e)
        self.state = "error"
        return translate("auth.errors.problem")
      }
    }),
    loadSubscriber: flow(function* loadSubscriber() {
      self.state = "pending"

      console.log("loading subscriber...")

      const RC = new RcApi()

      const info: GetGeneralResult = yield RC.getPurchaserInfo(self.parseId)

      if (info.kind === "ok") {
        if (info.data.entitlements?.access) {
          console.log(
            "Active Entitlement found: ",
            info.data.entitlements.access.product_identifier,
          )
          self.subscription = info.data.entitlements.access.product_identifier
          self.subscriptionUpdatedAt = new Date(info.data.entitlements.access.purchase_date)
        }
      } else {
        console.log("Error fetching Subscriber info: ", info.kind)
        self.state = "error"
      }
      self.state = "done"
    }),
    afterCreate() {
      this.loadSubscriber()
      self.state = "done"
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

type AccountType = Instance<typeof AccountModel>
export interface Account extends AccountType {}
type AccountSnapshotType = SnapshotOut<typeof AccountModel>
export interface AccountSnapshot extends AccountSnapshotType {}
export const createAccountDefaultModel = () => types.optional(AccountModel, {})
