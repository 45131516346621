/* eslint-disable react-native/no-inline-styles */
import React from "react"
import { observer } from "mobx-react-lite"
import { View, ViewStyle } from "react-native"
import { Button, Text } from "../../components"
// import { useStores } from "../../models"
import { color, spacing, typography } from "../../theme"
import { FLEX1 } from "../../utils/style"
import * as storage from "../../utils/storage"
import { NAVIGATION_PERSISTENCE_KEY } from "../../app"
import Nature from "../../components/nature/nature"
import usePlatform from "../../hooks/usePlatform"
import RNRestart from "react-native-restart" // Import package from node modules

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
}

export const GeneralErrorScreen = observer(function GeneralErrorScreen(error) {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()
  const { isWeb } = usePlatform()

  const restart = () => {
    storage.remove(NAVIGATION_PERSISTENCE_KEY)
    // Immediately reload the React Native Bundle
    RNRestart.Restart()
  }

  const reset = () => {
    storage.clear()
    // Immediately reload the React Native Bundle
    RNRestart.Restart()
  }

  console.log(error)

  return (
    <View style={ROOT}>
      <Nature />
      <View style={[FLEX1, { alignItems: "center" }]}>
        <Text
          tx="errors.ohno"
          style={{ textAlign: "center", padding: spacing[2], fontFamily: typography.primary }}
        />
        {!isWeb && (
          <>
            <Button
              onPress={restart}
              tx="errors.restart"
              style={{
                margin: 20,
              }}
              preset="secondary"
            />
            <Button
              preset="pale"
              onPress={reset}
              tx="errors.reset"
              textStyle={{ textAlign: "center", padding: spacing[2] }}
              style={{ padding: spacing[2] }}
            />
          </>
        )}
      </View>
    </View>
  )
})
