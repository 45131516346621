import { ApiResponse, ApisauceInstance, create } from "apisauce"
import { GetGeneralResult, GetGeneralResults } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { ApiConfig, DEFAULT_PARSE_API_CONFIG } from "./api-config"

const API_PAGE_SIZE = 50

export class ParseApi {
  apisauce: ApisauceInstance
  config: ApiConfig
  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_PARSE_API_CONFIG) {
    this.config = config
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: this.config.headers,
    })
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: this.config.headers,
    })
  }

  async restoreSession(): Promise<GetGeneralResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(`/users/me`)

      // the typical ways to die when calling an api
      if (!response.ok) {
        // The session has expired
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async registerUser(userData): Promise<GetGeneralResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.post(`/users`, {
        zipcode: userData.zipcode as number,
        ...userData,
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        if (response.data.code === 202) {
          return { kind: "emailtaken" }
        }
        console.log(response)
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async loginUser(username, password): Promise<GetGeneralResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(`/login`, {
        username: username,
        password: password,
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        __DEV__ && console.log(response)
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async logoutUser(): Promise<GetGeneralResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.post(`/logout`, {})

      // the typical ways to die when calling an api
      if (!response.ok) {
        console.log(response)
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: "ok", data: {} }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async updateUser(id: string, userData: any): Promise<GetGeneralResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.put("/users/" + id, {
        ...userData,
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        if (response.data.code === 202) {
          return { kind: "emailtaken" }
        }
        console.log(response)
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async resetPassword(email: string): Promise<GetGeneralResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.post(`/requestPasswordReset`, {
        email: email,
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async changePassword(objectId: string, password: string): Promise<GetGeneralResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.put("/users/" + objectId, {
        password: password,
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        console.log(response)
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      console.log(data)
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  /**
   * More like get 1000 of class
   * TBD: get all of class even if its more than 100
   */
  async getAllOfClass(classToFetch: string, where?, keys?, order?): Promise<GetGeneralResults> {
    try {
      // make the api call
      const classString = "/classes/".concat(classToFetch)
      const response: ApiResponse<any> = await this.apisauce.get(classString, {
        where: where,
        keys: keys,
        order: order,
        limit: 1000,
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getSomeOfClass(
    classToFetch: string,
    where: any,
    limit?: number,
    order?: string,
    keys?: string,
  ): Promise<GetGeneralResults> {
    try {
      // make the api call

      const options = {}
      const classString = "/classes/".concat(classToFetch)
      //     const whereString = "where=".concat(JSON.stringify(where))
      // const limitString = "limit=".concat(limit.toString())
      // const orderString = "order=".concat(order)
      // const keysString = "keys=".concat(keys)
      const response: ApiResponse<any> = await this.apisauce.get(classString, {
        where: JSON.stringify(where),
        limit: limit,
        order: order,
        key: keys,
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async uploadInstallation(installation): Promise<GetGeneralResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.post(`/installations`, {
        installation,
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      console.log(data)
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async createObject(className: string, createData: any): Promise<GetGeneralResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.post("/classes/" + className, {
        ...createData,
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        console.log(response)
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      console.log(data)
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async updateObject(
    className: string,
    createData: any,
    objectId: string,
  ): Promise<GetGeneralResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.put(
        "/classes/" + className + "/" + objectId,
        {
          ...createData,
        },
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        console.log(response)
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      console.log(data)
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async deleteObject(className: string, objectId: string): Promise<GetGeneralResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.delete(
        "/classes/" + className + "/" + objectId,
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        console.log(response)
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      console.log(data)
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async deleteUser(objectId: string): Promise<GetGeneralResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.delete("/users/" + objectId)

      // the typical ways to die when calling an api
      if (!response.ok) {
        console.log(response)
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      console.log(data)
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async sendChatRoomMessage(radioId, userId, message): Promise<GetGeneralResult> {
    try {
      // make the api call

      const response: ApiResponse<any> = await this.apisauce.post(`/classes/ChatRoom`, {
        radio_id: radioId,
        user_id: userId,
        message,
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async incrementPlayCount(podcastId): Promise<GetGeneralResult> {
    try {
      // make the api call

      const response: ApiResponse<any> = await this.apisauce.post(`/functions/incrementPlayCount`, {
        podcast: podcastId,
      })

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }
}
