/* eslint-disable generator-star-spacing */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { GetGeneralResults } from "../../services/api"
import { DEFAULT_PARSE_API_CONFIG } from "../../services/api/api-config"
import { ParseApi } from "../../services/api/parse-api"
import { withEnvironment } from "../extensions/with-environment"
import { PodcastModel } from "../podcast/podcast"

/**
 * Model description here for TypeScript hints.
 */
export const PodcastStoreModel = types
  .model("PodcastStore")
  .props({
    state: types.optional(types.enumeration("State", ["pending", "done", "error"]), "pending"),
    podcasts: types.optional(types.array(PodcastModel), []),
    currentPodcast: types.maybe(types.reference(PodcastModel)),
    isPlaying: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    load: flow(function* load() {
      console.log("loading podcasts")
      self.state = "pending"

      try {
        const parseApi = new ParseApi()
        const result: GetGeneralResults = yield parseApi.getAllOfClass("Podcast")

        if (result.kind === "ok" && result.data.results.length > 0) {
          self.podcasts = result.data.results.map((podcast) => {
            return {
              objectId: podcast.objectId,
              title: podcast.title,
              url: podcast.url,
              release: new Date(podcast.release.iso),
              info: podcast.info || [],
              program_detail_id: podcast.program_detail_id,
            }
          }) as any
          self.currentPodcast = self.podcasts[0].objectId as any
        }
        self.state = "done"
      } catch (error) {
        console.log("No Podcasts found ", error)
        self.state = "error"
      }
    }),
    afterCreate() {
      this.load()
    },
    getCurrentProgramDetailPodcasts(id: string) {
      console.log("getting podcasts for", id)
      return self.podcasts.filter((pod) => pod.program_detail_id.objectId === id && pod.IsReleased)
    },
    getRadioPodcasts(id: string) {
      const podcasts = []
      self.podcasts
        .filter((pod) => pod.program_id.radio_id.objectId === id && pod.IsReleased)
        .forEach((pod) => {
          const section = podcasts.findIndex((section) => section.title === pod.program_id.name)
          if (section > -1) {
            podcasts[section].data.push(pod) // already have a list- append to it
          } else {
            podcasts.push({ title: pod.program_id.name, cover: pod.program_id.cover, data: [pod] }) // no list for this month yet - create a new one
          }
        })

      return podcasts.map((section) => {
        return {
          title: section.title,
          cover: section.cover,
          data: section.data.sort((a, b) => a.sort - b.sort),
        }
      })
    },
    incrementPlayCount: flow(function* incrementPlayCount(id) {
      // self.currentPodcast = id
      // also increment play count
      try {
        const parseApi = new ParseApi({
          url: "http://parseapi-dev.back4app.com",
          ...DEFAULT_PARSE_API_CONFIG,
        })
        const result: GetGeneralResults = yield parseApi.incrementPlayCount(id)
        console.log(result)
        if (result.kind === "ok") {
          self.state = "done"
          return true
        } else {
          self.state = "done"
          return false
        }
      } catch (error) {
        console.log("Error incrementing Podcasts playcount: ", error)
        self.state = "error"
        return false
      }
    }),
    setIsPlaying(bool: boolean) {
      self.isPlaying = bool
    },
    setCurrentPodcast(id) {
      self.currentPodcast = id
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type PodcastStoreType = Instance<typeof PodcastStoreModel>
export interface PodcastStore extends PodcastStoreType {}
type PodcastStoreSnapshotType = SnapshotOut<typeof PodcastStoreModel>
export interface PodcastStoreSnapshot extends PodcastStoreSnapshotType {}
