/* eslint-disable indent */
import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Button, ChangePasswordModal, Container, Icon, Screen, Text } from "../../components"
import { useNavigation, useLinkTo, Link } from "@react-navigation/native"
import usePlatform from "../../hooks/usePlatform"
import { UniversalHeader } from "../../components/universal-header/universal-header"
import { FLEX1 } from "../../utils/style"
import { Linking } from "react-native"
import { useStores } from "../../models"
import { View } from "dripsy"
import { MyLanguage } from "../../i18n/i18n"
import theme from "../../theme/theme"
import { LoadingScreen } from "../../components/loading-screen/loading-screen"
import { Chip } from "react-native-paper"
import { palette } from "../../theme/palette"
import { typography } from "../../theme"

export const AccountScreen = observer(function AccountScreen() {
  // Pull in one of our MST stores
  const rootStore = useStores()
  const { account } = rootStore
  const [showPWC, setPWC] = useState(false)
  const { isWeb } = usePlatform()
  const linkTo = useLinkTo()
  const nav = useNavigation()

  const hidePWC = () => {
    setPWC(false)
  }

  const openPWC = () => {
    setPWC(true)
  }

  useEffect(() => {
    account.loadSubscriber()
  }, [])

  const linkToAppleManageSubscriptionsInAppStore = () =>
    Linking.openURL("itms-apps://apps.apple.com/account/subscriptions")

  const linkToGoogleManageSubscriptionsInPlayStore = () =>
    Linking.openURL("https://play.google.com/store/account/subscriptions")

  const linkToStripePortal = () =>
    Linking.openURL(`https://universalapp-purchases.herokuapp.com/portal/${rootStore.sessiontoken}`)

  if (account.state !== "done") return <LoadingScreen />
  console.log(account)

  return (
    <Screen style={FLEX1} preset="scroll">
      <Container gradient>
        {!isWeb && <UniversalHeader nav={nav} />}
        <View sx={{ margin: "$s", padding: "$s", flex: 1 }}>
          <View
            sx={{ my: "$xxl", mx: "$l", flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text preset="header" tx="acc.my" />
            {account.hasSubscription && (
              <Icon
                name={account.subscription.icon}
                size={30}
                color={theme.colors.$mainTextColor}
              />
            )}
          </View>
          <View sx={{ mb: "$xxl" }}>
            {account.hasCode && (
              <View sx={{ margin: "$l", flexDirection: "row", justifyContent: "flex-start" }}>
                <Text preset="stack" tx="sub.success" />
              </View>
            )}
            {account.hasSubscription ? (
              <View sx={{ margin: "$l", flexDirection: "row", justifyContent: "space-between" }}>
                <Text preset="stack" style={{ marginTop: 5 }} tx="sub.current" />
                <Chip
                  icon={account.subscription.maticon}
                  style={{ width: "40%", backgroundColor: palette.alphawhite }}
                  disabled
                >
                  <Text>{account.subscription.price}.- </Text>
                  <Text
                    tx={account.subscription.interval === "month" ? "sub.monthly" : "sub.annualy"}
                  />
                </Chip>
              </View>
            ) : (
              <View
                sx={{
                  margin: "$l",
                  padding: "$s",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text preset="stack" tx="acc.nosub" />
              </View>
            )}
            {account.hasFreeTime && (
              <View
                sx={{
                  margin: "$l",
                  padding: "$s",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text preset="stack" tx="acc.freedaysleft" />
                <Text preset="stack">{account.freeDaysLeft}</Text>
              </View>
            )}
            <View
              sx={{
                margin: "$l",
                padding: "$s",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text preset="stack" tx="acc.created" />
              <Text preset="stack">{account.createdAt.toLocaleDateString(MyLanguage)}</Text>
            </View>
          </View>
          <View sx={{ mt: "$xxl", justifyContent: "flex-end", flex: 1 }}>
            {account.hasSubscription ? (
              <View sx={{ padding: "$s" }}>
                <Button preset="secondary" onPress={account.subscription.manage} tx="sub.manage" />
              </View>
            ) : (
              <View sx={{ padding: "$s", mb: "$xl" }}>
                {isWeb ? (
                  <Link to={"/subscription"}>
                    <Button sx={{ width: "100%" }} preset="primary" tx="sub.getSubButtonText" />
                  </Link>
                ) : (
                  <Button
                    onPress={() => linkTo("/subscription")}
                    preset="primary"
                    tx="sub.getSubButtonText"
                  />
                )}
              </View>
            )}
            <View sx={{ padding: "$s" }}>
              <Button preset="secondary" tx="acc.changepw" onPress={openPWC} />
            </View>
            {/* Coming Soon
            <View sx={{ padding: "$s" }}>
              <Button
                preset="secondary"
                disabled
                tx="acc.settings"
                onPress={() => linkTo("/settings")}
              />
            </View> */}
            <View sx={{ padding: "$s" }}>
              {isWeb ? (
                <Link to={"/goodbye"}>
                  <Button sx={{ width: "100%" }} preset="secondary" tx="auth.logout" />
                </Link>
              ) : (
                <Button onPress={() => linkTo("/goodbye")} preset="secondary" tx="auth.logout" />
              )}
            </View>
            {/* Coming Soon
             <View sx={{ padding: "$s", my: "$xl" }}>
              <Button preset="danger" disabled tx="acc.delete" onPress={() => linkTo("/delete")} />
            </View> Coming soon */}
          </View>
        </View>
        <ChangePasswordModal visible={showPWC} dismiss={hidePWC} action={account.changePassword} />
      </Container>
    </Screen>
  )
})
