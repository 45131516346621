/* eslint-disable camelcase */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { MyLanguage } from "../../i18n/i18n"
import { GetGeneralResult, GetGeneralResults, ParseApi } from "../../services/api"
import { withEnvironment } from "../extensions/with-environment"
import { MetaModel } from "../meta/meta"

/**
 * Model description here for TypeScript hints.
 */
export const MetaStoreModel = types
  .model("MetaStore")
  .props({
    meta: types.maybe(MetaModel),
    state: types.enumeration("State", ["pending", "done", "error"]),
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .extend(withEnvironment)
  .actions((self) => ({
    // eslint-disable-next-line generator-star-spacing
    load: flow(function* load() {
      self.state = "pending"
      console.log("loading Meta...")
      try {
        const parseApi = new ParseApi()
        const result: GetGeneralResults = yield parseApi.getSomeOfClass("Meta", {
          locale: MyLanguage,
        })

        if (result.kind === "ok") {
          console.log("Meta Response: ", result.data)
          const { support, support_json, about, timeline, privacy, vision } = result.data.results[0]
          self.meta = {
            locale: MyLanguage,
            vision: vision || "",
            about: about || "",
            support: support || "",
            timeline: timeline || "",
            privacy: privacy || "",
            faq: support_json || [],
          }
        } else {
          console.log("Error getting Meta:", result)
        }
        self.state = "done"
        console.log("Done Loading Meta")
      } catch (error) {
        console.log("Failed to load Meta:", error)
        self.state = "error"
      }
    }),
    afterCreate() {
      this.load()
      self.state = "done"
    },
    // getMeta(locale: string): Meta {
    //   return self.meta.find((meta) => meta.locale === locale)
    // },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type MetaStoreType = Instance<typeof MetaStoreModel>
export interface MetaStore extends MetaStoreType {}
type MetaStoreSnapshotType = SnapshotOut<typeof MetaStoreModel>
export interface MetaStoreSnapshot extends MetaStoreSnapshotType {}
