import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { TextStyle, View, ViewStyle } from "react-native"
import { Container, Screen, Text } from "../../components"
import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../../models"
import { color, spacing, typography } from "../../theme"
import { UniversalHeader } from "../../components/universal-header/universal-header"
import { useStores } from "../../models"
import { MyLanguage } from "../../i18n/i18n"
import { LoadingScreen } from "../../components/loading-screen/loading-screen"
import { palette } from "../../theme/palette"
import Bugsnag from "@bugsnag/react-native"
import Autolink from "react-native-autolink"
import { FLEX1, LINK } from "../../utils/style"
import usePlatform from "../../hooks/usePlatform"

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
}
const TEXT_CONTAINER: ViewStyle = {
  padding: spacing[3],
  backgroundColor: palette.white,
}

const TEXT: TextStyle = {
  fontFamily: typography.primary,
  color: palette.darkpurple,
  fontSize: typography.size[3],
}

export const AboutScreen = observer(function AboutScreen() {
  // Bugsnag.leaveBreadcrumb("Screen: About App")
  const { metaStore } = useStores()
  const { isWeb } = usePlatform()

  const nav = useNavigation()

  if (!metaStore || !metaStore.meta || metaStore.state === "pending") return <LoadingScreen />
  return (
    <Screen style={[ROOT, isWeb && FLEX1]} preset="scroll">
      <Container>
        {!isWeb && <UniversalHeader nav={nav} colorful />}
        <View style={TEXT_CONTAINER}>
          <Autolink text={metaStore.meta.vision} style={TEXT} linkStyle={LINK} />
        </View>
      </Container>
    </Screen>
  )
})
