import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { simpleHash } from "../../utils/hash"
import { LinkModel } from "../link/link"
import { ProgramDetailModel } from "../program-detail/program-detail"
import { ProgramModel } from "../program/program"

/**
 * Model description here for TypeScript hints.
 */
export const PodcastModel = types
  .model("Podcast")
  .props({
    objectId: types.identifier,
    index: types.optional(types.number, 10),
    title: types.string,
    url: types.string,
    release: types.Date,
    info: types.optional(types.array(LinkModel), []),
    program_detail_id: types.reference(ProgramDetailModel),
  })
  .views((self) => ({
    get PodcastHash() {
      return simpleHash(self.objectId)
    },
    get IsReleased() {
      return self.release.getTime() < Date.now().valueOf()
    },
    get TrackObject() {
      return {
        id: self.objectId,
        url: encodeURI(self.url),
        title: self.title,
        artist: new Date(self.release).toLocaleDateString(),
      }
    },
    get program_id() {
      return self.program_detail_id.program_id
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type PodcastType = Instance<typeof PodcastModel>
export interface Podcast extends PodcastType {}
type PodcastSnapshotType = SnapshotOut<typeof PodcastModel>
export interface PodcastSnapshot extends PodcastSnapshotType {}
