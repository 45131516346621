import * as React from "react"
import { View, ViewStyle, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { spacing } from "../../theme"
import { Text } from "../"
import { palette } from "../../theme/palette"
import usePlatform from "../../hooks/usePlatform"
import { FAB } from "react-native-paper"
import { translate } from "../../i18n"

const SIDE_BUTTONS: ViewStyle = {
  marginVertical: spacing[2],
  paddingVertical: spacing[4],
  borderTopLeftRadius: 30,
  borderBottomLeftRadius: 30,
  backgroundColor: palette.darkpurple,
  elevation: 9,
}

const FAB_STYLE: ViewStyle = {
  position: "absolute",
  right: 0,
  top: 100,
  backgroundColor: palette.purple,
}

const TEXT_LENGTH = 65
const TEXT_HEIGHT = 60
const OFFSET = TEXT_LENGTH / 2 - TEXT_HEIGHT / 2

export interface PodcastButtonProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  onPress: any
}

/**
 * Describe your component here
 */
export const PodcastButton = observer(function PodcastButton(props: PodcastButtonProps) {
  const { style, onPress } = props

  // if (isWeb)
  //   return (
  //     <FAB
  //       style={FAB_STYLE}
  //       onPress={onPress}
  //       label={translate("podcast.podcasts")}
  //       icon="microphone"
  //     />
  //   )

  return (
    <TouchableOpacity style={SIDE_BUTTONS} onPress={onPress}>
      <View style={{ width: TEXT_HEIGHT, height: TEXT_LENGTH }}>
        <Text
          // allowFontScaling={false}
          style={{
            transform: [
              { rotate: "270deg" },
              { translateX: -OFFSET + 1 },
              { translateY: OFFSET + 2 },
            ],
            width: TEXT_LENGTH + 5,
            height: TEXT_HEIGHT,
            color: "white",
          }}
          tx={"podcast.podcasts"}
        />
      </View>
    </TouchableOpacity>
  )
})
