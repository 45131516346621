import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { TextStyle, ViewStyle, TouchableOpacity } from "react-native"
import { Button, Container, Icon, Screen, Text } from "../../components"
import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../../models"
import { color, spacing, typography } from "../../theme"
import { UniversalHeader } from "../../components/universal-header/universal-header"
import { useStores } from "../../models"
import { LoadingScreen } from "../../components/loading-screen/loading-screen"
import { palette } from "../../theme/palette"
import { FLEX1, MB } from "../../utils/style"
import usePlatform from "../../hooks/usePlatform"
import Accordion from "react-native-collapsible/Accordion"
import * as Animatable from "react-native-animatable"
import { View } from "@dripsy/core"
import { openUrl } from "../../utils/openUrl"

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
}

const TEXT_CONTAINER: ViewStyle = {
  padding: spacing[3],
  backgroundColor: palette.white,
}

const TEXT: TextStyle = { color: palette.darkpurple, fontSize: typography.size[3] }

export const SupportScreen = observer(function SupportScreen() {
  const { metaStore } = useStores()
  const { isWeb } = usePlatform()
  const [activeSections, setActiveSections] = useState([])

  const nav = useNavigation()

  const linkToSupport = () => {
    openUrl("mailto:support@universalapp.ch")
  }

  function setActive(indexes) {
    setActiveSections(indexes)
  }

  const SectionHeader = (section, index, isActive) => {
    return (
      <Animatable.View duration={400} transition="backgroundColor">
        <View sx={{ flexDirection: "row", padding: "$m", mr: "$l" }}>
          <Icon name={isActive ? "chevron-up" : "chevron-down"} color={palette.darkpurple} />
          <Text preset="bold" style={{ marginLeft: 10 }}>
            {section.question}
          </Text>
        </View>
      </Animatable.View>
    )
  }

  const SectionItem = (section) => {
    return (
      <Animatable.View duration={400} transition="backgroundColor">
        <View sx={{ flexDirection: "row", padding: "$m" }}>
          <Text preset="default" style={{ marginLeft: 34 }}>
            {section.answer}
          </Text>
        </View>
      </Animatable.View>
    )
  }

  if (!metaStore.meta || metaStore.state === "pending") return <LoadingScreen />
  console.log(metaStore.meta)
  return (
    <Screen style={[ROOT, isWeb && FLEX1]} preset="scroll">
      <Container>
        {!isWeb && <UniversalHeader nav={nav} colorful />}
        <View sx={{ backgroundColor: "$mainBackground" }}>
          <View sx={{ margin: "$s", padding: "$s" }}>
            <View sx={{ my: "$xl", ml: "$l" }}>
              <Text style={[TEXT, MB]}>{metaStore.meta.support}</Text>
            </View>
            <View sx={{ mb: "$xxl", mx: "$l" }}>
              <Accordion
                activeSections={activeSections}
                sections={metaStore.meta.faq}
                touchableComponent={TouchableOpacity}
                expandMultiple={false}
                renderHeader={SectionHeader}
                renderContent={SectionItem}
                duration={400}
                onChange={setActive}
                keyExtractor={(item) => item.question}
              />
            </View>
          </View>
          <View sx={{ my: "$xl", justifyContent: "flex-end" }}>
            <Button preset="secondary" onPress={linkToSupport} tx="contactsupport" />
          </View>
        </View>
      </Container>
    </Screen>
  )
})
